
import SideHome from '@/components/side/SideHome.vue';
import SideGames from '@/components/side/SideGames.vue';
import SideAbout from '@/components/side/SideAbout.vue';
import SideNews from '@/components/side/SideNews.vue';
import SideContact from '@/components/side/SideContact.vue';
import { useRoute } from 'vue-router';
import { defineComponent } from 'vue';

export default defineComponent({
	name: 'SideContainer',
	computed: {
		currentSideContent() {
			const route = useRoute();
			switch (route.name) {
				case 'home':
					return SideHome;
				case 'games':
					return SideGames;
				case 'about':
					return SideAbout;
				case 'news':
					return SideNews;
				case 'contact':
					return SideContact;
				default:
					return SideHome;
			}
		},
	},
});
