import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';

const routes: Array<RouteRecordRaw> = [
	{
		path: '/',
		alias: '/home',
		name: 'home',

		component: () => import('../components/Home.vue'),
	},
	{
		path: '/games',
		name: 'games',
		component: () => import('../components/Games.vue'),
	},
	{
		path: '/about',
		name: 'about',
		component: () => import('../components/About.vue'),
	},
	{
		path: '/news',
		name: 'news',
		component: () => import('../components/News.vue'),
	},
	{
		path: '/contact',
		name: 'contact',
		component: () => import('../components/Contact.vue'),
	},
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
});

export default router;
