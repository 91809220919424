import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "carousel__item" }
const _hoisted_2 = { class: "sldie_title" }
const _hoisted_3 = { class: "slide_list" }
const _hoisted_4 = ["src", "alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_slide = _resolveComponent("slide")!
  const _component_pagination = _resolveComponent("pagination")!
  const _component_Carousel = _resolveComponent("Carousel")!

  return (_openBlock(), _createBlock(_component_Carousel, {
    autoplay: 3000,
    transition: 1000,
    "wrap-around": true
  }, {
    addons: _withCtx(() => [
      _createVNode(_component_pagination)
    ]),
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.slides, (slide) => {
        return (_openBlock(), _createBlock(_component_slide, { key: slide }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("p", null, _toDisplayString(slide.title), 1)
              ]),
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("img", {
                  src: slide.slide_image,
                  alt: slide.game_name
                }, null, 8, _hoisted_4),
                _createVNode(_component_router_link, {
                  to: slide.button_url,
                  class: "to_game"
                }, null, 8, ["to"])
              ])
            ])
          ]),
          _: 2
        }, 1024))
      }), 128))
    ]),
    _: 1
  }))
}