
import { defineComponent } from 'vue';
import { Carousel, Pagination, Slide } from 'vue3-carousel';
import axios from 'axios';
import 'vue3-carousel/dist/carousel.css';
import '@/mocks/slide_main';
import Slides from '@/models/Slides';

export default defineComponent({
	name: 'SideHome',
	components: {
		Carousel,
		Slide,
		Pagination,
	},
	data() {
		return {
			slides: [] as Slides[],
		};
	},
	methods: {
		async getList(): Promise<void> {
			const response = await axios.get('/api/slide/main');
			this.slides = response.data;
		},
	},
	async created(): Promise<void> {
		await this.getList();
	},
});
