import { createStore } from 'vuex';
import { Locales } from '@/i18n/locales';
import { defaultLocale } from '@/i18n';

export default createStore({
	state: {
		language: defaultLocale,
	},
	mutations: {
		SET_LANGUAGE: (state, payload: Locales) => {
			state.language = payload;
		},
	},
	getters: {
		current: (state) => {
			return state.language;
		},
	},
	actions: {},
	modules: {},
});
