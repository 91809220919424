import fixture from 'can-fixture';

fixture('GET /api/slide/main', () => {
	return [
		{
			id: 1,
			title: 'NEW GAME',
			game_name: 'Ninja Assissin',
			slide_image: '/data/side/side_ninja_home.png',
			button_url: '/games',
			launch_year: '',
			launch_month: '',
			launch_day: '',
		},
		{
			id: 2,
			title: 'NEW GAME',
			game_name: 'Princess of SaKura',
			slide_image: '/data/side/side_sakura_home.png',
			button_url: '/games',
			launch_year: '',
			launch_month: '',
			launch_day: '',
		},
		{
			id: 3,
			title: 'NEW GAME',
			game_name: 'Bonanza Crush',
			slide_image: '/data/side/side_bonanza_home.jpg',
			button_url: '/games',
			launch_year: '',
			launch_month: '',
			launch_day: '',
		},
		{
			id: 4,
			title: 'NEW GAME',
			game_name: 'Chicago City',
			slide_image: '/data/side/side_chicago_home.jpg',
			button_url: '/games',
			launch_year: '',
			launch_month: '',
			launch_day: '',
		},
		{
			id: 5,
			title: 'NEW GAME',
			game_name: 'Super Phoenix',
			slide_image: '/data/side/side_phoenix_home.jpg',
			button_url: '/games',
			launch_year: '',
			launch_month: '',
			launch_day: '',
		},
		{
			id: 6,
			title: 'NEW GAME',
			game_name: 'Squid of Death',
			slide_image: '/data/side/side_squid_home.jpg',
			button_url: '/games',
			launch_year: '',
			launch_month: '',
			launch_day: '',
		},
		{
			id: 7,
			title: 'NEW GAME',
			game_name: 'Casino Zeppelin',
			slide_image: '/data/side/side_zeppelin_home.jpg',
			button_url: '/games',
			launch_year: '',
			launch_month: '',
			launch_day: '',
		},
	];
});

export default fixture;
