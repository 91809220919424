
import { defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';
import { defaultLanguage } from '@/i18n';

export default defineComponent({
	name: 'Header',
	data() {
		return {
			currentLanguage: defaultLanguage,
			toggledNav: false,
		};
	},
	methods: {
		changeLanguage() {
			this.$store.commit('SET_LANGUAGE', this.currentLanguage);
			this.$i18n.locale = this.currentLanguage;
		},
		closeNav() {
			let nav = document.querySelector('.side_nav');
			if (nav) {
				nav.classList.remove('show');
			}
		},
	},
	setup() {
		const { t } = useI18n({
			userScope: 'global',
			inheritLocale: true,
			locale: defaultLanguage,
		});

		return { t };
	},
});
