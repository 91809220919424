<template>
	<footer>
		<div class="footer_inner">
			<div class="footer_logo">
				<img src="@/assets/images/logo.png" alt="Logo" />
			</div>
			<div class="footer_desc">
				{{ t('footer.desc1') }}
				<br />
				{{ t('footer.desc2') }}
				<br />
				{{ t('footer.desc3') }}
				<br />
				{{ t('footer.desc4') }}
			</div>
		</div>
	</footer>
</template>

<script>
import { useI18n } from 'vue-i18n';
import { defineComponent } from 'vue';

export default defineComponent({
	name: 'Footer',
	setup() {
		const { t } = useI18n({
			userScope: 'global',
			inheritLocale: true,
		});
		return { t };
	},
});
</script>

<style scoped></style>
