import fixture from 'can-fixture';

fixture('GET /api/slide/game', () => {
	return [
		{
			id: 1,
			title: 'NEW GAME',
			game_name: 'Wild West Saloon',
			slide_image: '/data/side/side_wildwest_220324.jpg',
			button_url: '/games',
			launch_year: '2021',
			launch_month: '01',
			launch_day: '08',
		},
		{
			id: 2,
			title: 'NEW GAME',
			game_name: 'Bonanza Crush',
			slide_image: '/data/side/side_bonanza_games.jpg',
			button_url: '/games',
			launch_year: '2021',
			launch_month: '01',
			launch_day: '08',
		},
		{
			id: 3,
			title: 'NEW GAME',
			game_name: 'Chicago City',
			slide_image: '/data/side/side_chicago_games.jpg',
			button_url: '/games',
			launch_year: '2021',
			launch_month: '01',
			launch_day: '08',
		},
		{
			id: 4,
			title: 'NEW GAME',
			game_name: 'Super Phoenix',
			slide_image: '/data/side/side_phoenix_games.jpg',
			button_url: '/games',
			launch_year: '2021',
			launch_month: '01',
			launch_day: '08',
		},
		{
			id: 5,
			title: 'NEW GAME',
			game_name: 'Squid of Death',
			slide_image: '/data/side/side_squid_games.jpg',
			button_url: '/games',
			launch_year: '2021',
			launch_month: '01',
			launch_day: '08',
		},
		{
			id: 6,
			title: 'NEW GAME',
			game_name: 'Casino Zeppelin',
			slide_image: '/data/side/side_zeppelin_games.jpg',
			button_url: '/games',
			launch_year: '2021',
			launch_month: '01',
			launch_day: '08',
		},
	];
});

export default fixture;
