import { Locales } from './locales';

import eng from './locales/eng.json';
import chan_a from './locales/chn_a.json';
import chan_b from './locales/chn_b.json';
import mys from './locales/mys.json';
import tha from './locales/tha.json';
import idn from './locales/idn.json';
import jpn from './locales/jpn.json';
import vnm from './locales/vnm.json';
import kor from './locales/kor.json';

export const messages = {
	[Locales.ENG]: eng,
	[Locales.CHNA]: chan_a,
	[Locales.CHNB]: chan_b,
	[Locales.MYS]: mys,
	[Locales.THA]: tha,
	[Locales.IDN]: idn,
	[Locales.JPN]: jpn,
	[Locales.VNM]: vnm,
	[Locales.KOR]: kor,
};

export const defaultLanguage = 'ENG';
export const defaultLocale = Locales.ENG;
