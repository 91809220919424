import { createApp } from 'vue';
import { createI18n } from 'vue-i18n';
import App from './App.vue';
import router from './router';
import store from './store';

// language
import { defaultLocale, messages } from '@/i18n';

const i18n = createI18n({
	legacy: false,
	locale: defaultLocale,
	fallbackLocale: defaultLocale,
	globalInjection: true,
	messages,
});

createApp(App).use(store).use(router).use(i18n).mount('#app');
