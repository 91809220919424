
import { defineComponent } from 'vue';
import Header from '@/components/layout/Header.vue';
import Footer from '@/components/layout/Footer.vue';
import SideContainer from '@/components/layout/SideContainer.vue';

export default defineComponent({
	name: 'App',
	components: {
		Header,
		Footer,
		SideContainer,
	},
});
