export enum Locales {
	ENG = 'eng',
	CHNA = 'chna',
	CHNB = 'chnb',
	MYS = 'mys',
	THA = 'tha',
	IDN = 'idn',
	JPN = 'jpn',
	VNM = 'vnm',
	KOR = 'kor',
}

export const LOCALES = [
	{ value: Locales.ENG, caption: 'English' },
	{ value: Locales.CHNA, caption: '簡體中文' },
	{ value: Locales.CHNB, caption: '繁體中文' },
	{ value: Locales.MYS, caption: 'Melayu' },
	{ value: Locales.THA, caption: 'ไทย' },
	{ value: Locales.IDN, caption: 'bahasa' },
	{ value: Locales.JPN, caption: '日本語' },
	{ value: Locales.VNM, caption: 'Tiếng Việt' },
	{ value: Locales.KOR, caption: '한국어' },
];
